import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import * as S from './styled'
import { gtag } from 'src/services/analytics'

const Jobs = ({title}) => {
  const { 
    allStrapiVaga: { nodes: jobslist }
  } = useStaticQuery(
      graphql`
        query {
          allStrapiVaga(sort: {fields: published_at, order: DESC}) {
            nodes {
              nome
              link
              cor_background
              cor_texto
            }
          }
        }
    `
  )

  const handleOnClick = (job) => {
    gtag('job_click', {name: job.name})
    window.open(job.link, '_blank')
  }

  return (
    <S.ContentWrapper>
      <S.Title>
        {title}
      </S.Title>
      {jobslist.map(job => <S.JobButton 
          onClick={() => handleOnClick(job)}
          background={job.cor_background}
          color={job.cor_texto}
        >
        {job.nome}
        </S.JobButton>
      )}
    </S.ContentWrapper>
  )
}

export default Jobs