import styled from 'styled-components';
import media from 'styled-media-query';
import Image from 'gatsby-image';

import {colors, globalMaxWidth} from 'src/commons'

export const Banner = styled(Image)`
  width: 100vw;
  height: 450px;

  ${media.lessThan("large")`
    height: 400px;
  `}
`

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 50px;

  ${media.lessThan("900px")`
    flex-direction: column;
    justify-content: center;
  `}

  ${media.lessThan("600px")`
    padding: 20px;
  `}

  ${media.greaterThan(globalMaxWidth)`
    padding: 0 calc(((100vw - ${globalMaxWidth}) / 2) + 50px);
  `}
`

export const InfosWrapper = styled.div`
  width: auto;
  max-width: 70rem;
`

export const Title = styled.h1`
  color: ${colors.green};
  font-size: 16px;
  font-weight: medium;
  margin: 20px 0;
`

export const FormWrapper = styled.div`
  padding: 50px 60px;
  min-width: 450px;
  max-width: 600px;

  ${media.lessThan("900px")`
    min-width: unset;
    padding: 0px;
  `}
`