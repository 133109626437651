import React from "react"

import SEO from "src/components/seo"
import Layout from "src/components/Layout"

import WorkWithUs from "src/components/WorkWithUs"

const TrabalheConosco = () => (
  <Layout>
    <SEO title="Venha fazer parte do time VilaApps" />
    <WorkWithUs />
  </Layout>
)

export default TrabalheConosco
