import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Jobs from './Jobs'

import {MarkdownContent} from 'src/styles/base'
import * as S from './styled'

const WorkWithUs = () => {
  const {
    strapiTrabalheConosco: { titulo, descricao, banner, titulo_vagas_abertas }
  } = useStaticQuery(
    graphql`
      query {
        strapiTrabalheConosco {
          banner {
            childImageSharp {
              fluid(maxWidth: 1900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          titulo
          descricao
          titulo_vagas_abertas
        }
      }
    `
  );

  return (
    <>
      <S.Banner fluid={banner.childImageSharp.fluid} />
      <S.ContentWrapper>
        <S.InfosWrapper>
          <S.Title>
            {titulo}
          </S.Title>
          <MarkdownContent>
            {descricao}
          </MarkdownContent>
        </S.InfosWrapper>

        <S.FormWrapper>
          <Jobs
            title={titulo_vagas_abertas}
          />
        </S.FormWrapper>
      </S.ContentWrapper>
    </>
  )
}

export default WorkWithUs