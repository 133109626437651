import styled from 'styled-components';

import {colors} from 'src/commons'

export const ContentWrapper = styled.div`
  width: 100%;
`

export const Title = styled.h1`
  color: ${colors.black};
  font-size: 18px;
  font-weight: 400;
  margin: 20px 0;
  border-bottom: 2px dashed ${colors.black}90;
`

export const JobButton = styled.button`
  background: ${props => props.background ? props.background : colors.purple};
  border-radius: 10px;
  cursor: pointer;
  padding: 15px 10px;
  width: 100%;
  transition: 0.2s;
  margin-bottom: 10px;

  color: ${props => props.color ? props.color : colors.white};
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;

  &:hover {
    opacity: 0.9;
    font-size: 18px;
  }

  :focus {
    outline: none;
  }

  :active {
    background: ${props => props.background ? props.background : colors.purple};
  }
`